import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery7.css'

const Gallery7 = (props) => {


  const ImageWithCaption = ({ 
    src, 
    alt, 
    title, 
    material, 
    dimensions, 
    className 
  }) => (
    <div className="image-container relative group">
    <img
      src={src}
      alt={alt}
      className={`${className} w-full h-full object-cover`}
    />
    <div className="image-caption absolute bottom-0 left-0 right-0 bg-white bg-opacity-90 p-3 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
      <h3 className="text-red-900 font-semibold text-sm mb-1 caption-title">{title}</h3>
      <p className="text-gray-700 text-xs caption-details">Caracteristicas: {material}</p>
      <p className="text-gray-700 text-xs caption-details">Medidas: {dimensions}</p>
    </div>
  </div>
  );



  return (
    <div className="gallery7-gallery3 thq-section-padding">
      <div className="gallery7-max-width">
        <div className="gallery7-section-title">
          <h2 className="gallery7-text1 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="gallery7-text4">Portafolio de trabajos realizados</span>
              </Fragment>
            )}
          </h2>
          <span className="gallery7-text2 thq-body-large">
            {props.content1 ?? (
              <Fragment>
                <span className="gallery7-text3">
                  Estos trabajos fueron realizados utilizando la tecnologia FDM con impresion 3D, para los cuales el desarrollo de algunos de estos se dio desde el diseño inicial, y para otros tras la generacion del modelo fisico se realizo post-procesado, los trabajos realizados varian desde piezas de modelado a escala hasta piezas mecanicas y de uso comun ajustado a las necesidades de cada cliente.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="gallery7-container1">
          <div className="gallery7-content">
            <div className="gallery7-container2 ">
              <ImageWithCaption
            src={props.image1Src}
            alt={props.image1Alt}
            title={props.image1Title}
            material={props.image1Material}
            dimensions={props.image1Dimensions}
            className="gallery7-image1  thq-img-ratio-1-1"
          />
          <ImageWithCaption
            src={props.image2Src}
            alt={props.image2Alt}
            title={props.image2Title}
            material={props.image2Material}
            dimensions={props.image2Dimensions}
            className="gallery7-image2 thq-img-ratio-1-1"
          />
        </div>
        <div className="gallery7-container3">
          <ImageWithCaption
            src={props.image3Src}
            alt={props.image3Alt}
            title={props.image3Title}
            material={props.image3Material}
            dimensions={props.image3Dimensions}
            className="gallery7-image3 thq-img-ratio-8-3"
          />
          <ImageWithCaption
            src={props.image4Src}
            alt={props.image4Alt}
            title={props.image4Title}
            material={props.image4Material}
            dimensions={props.image4Dimensions}
            className="gallery7-image4 thq-img-ratio-8-3"
          />
          <ImageWithCaption
            src={props.image5Src}
            alt={props.image5Alt}
            title={props.image5Title}
            material={props.image5Material}
            dimensions={props.image5Dimensions}
            className="gallery7-image5 thq-img-ratio-8-3"
          />
        </div>
        <div className="gallery7-container4">
          <ImageWithCaption
            src={props.image6Src}
            alt={props.image6Alt}
            title={props.image6Title}
            material={props.image6Material}
            dimensions={props.image6Dimensions}
            className="gallery7-image6 thq-img-ratio-1-1"
          />
          <ImageWithCaption
            src={props.image7Src}
            alt={props.image7Alt}
            title={props.image7Title}
            material={props.image7Material}
            dimensions={props.image7Dimensions}
            className="gallery7-image7 thq-img-ratio-1-1"
          />
        </div>
            </div>
          </div>
        </div>
      </div>

  )
}

Gallery7.defaultProps = {
  content1: undefined,
  heading1: undefined,
  image3Src: '/Images/galeria03.jpg',
  image7Src: '/Images/galeria07.jpg',
  image4Src: '/Images/galeria04.jpg',
  image5Src: '/Images/galeria05.jpg',
  image6Src: '/Images/galeria06.jpg',
  image2Src: '/Images/galeria02.png',
  image1Src: '/Images/galeria01.png',
  image6Alt: 'image',
  image2Alt: 'image',
  image4Alt: 'image',
  image5Alt: 'image',
  image7Alt: 'image',
  image3Alt: 'image',
  image1Alt: 'image',
  
  image1Dimensions: '10 x 10 x 10 cm',
  image2Dimensions: '10 x 10 x 10 cm',
  image3Dimensions: '10 x 10 x 10 cm',
  image4Dimensions: '10 x 10 x 10 cm',
  image5Dimensions: '10 x 10 x 10 cm',
  image6Dimensions: '10 x 10 x 10 cm',
  image7Dimensions: '10 x 10 x 10 cm',
  image1Title: 'Figura de modelismo coleccionable: Mr. Bills - Dragon Ball Super',
  image2Title: 'Figura de modelismo coleccionable: Goku - Dragon Ball Super',
  image3Title: 'Reposa vasos/tazas para cafeteria con logo personalizado',
  image4Title: 'Soporte personalizado para piedras de cuarzo',
  image5Title: 'Cortadores de galletas personalizados',
  image6Title: 'Elementos decorativos para exposicion tematica',
  image7Title: 'Bisagra para Tapa de pecera, con candado de seguridad',
  image1Material: '[Material: PLA], [Post Procesado: SI], [Pintura: Acrilica (Aerografo/Pincel)], [Acabado: Mate]',
  image2Material: '[Material: PLA], [Post Procesado: SI], [Pintura: Acrilica (Aerografo/Pincel)], [Acabado: Brillante]',
  image3Material: '[Material: Madera/PLA], [Post Procesado: NO], [Pintura: N/A], [Acabado: MATE]',
  image4Material: '[Material: ABS], [Post Procesado: NO], [Pintura: N/A], [Acabado: MATE]',
  image5Material: '[Material: PETG], [Post Procesado: NO], [Pintura: N/A], [Acabado: MATE]',
  image6Material: '[Material: PLA], [Post Procesado: NO], [Pintura: N/A], [Acabado: MATE]',
  image7Material: '[Material: ABS], [Post Procesado: NO], [Pintura: N/A], [Acabado: MATE]',
}

Gallery7.propTypes = {
  image3Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image4Alt: PropTypes.string,
  content1: PropTypes.element,
  image6Alt: PropTypes.string,
  image5Src: PropTypes.string,
  heading1: PropTypes.element,
  image2Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image2Src: PropTypes.string,
  image1Src: PropTypes.string,
}

export default Gallery7
