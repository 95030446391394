import React from 'react'

import PropTypes from 'prop-types'
 import {  Link, Outlet } from 'react-router-dom'

import './steps.css'

const Steps = (props) => {
  return (
    <div className="steps-container1 thq-section-padding">
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container2 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">
              Descubre lo que podemos crear para ti y el proceso que llevamos a cabo para realizarlo
            </h2>
            <p className="thq-body-large">
              Ofrecemos la mayor calidad de nuestros productos y siempre buscamos la satisfaccion de nuestros clientes, detallando las necesidades de cada uno de ellos en los trabajos a realizar.
            </p>
            <div className="steps-actions">
        
      <button type="button" className="thq-button-filled thq-button-animated steps-button"   >
                <Link to="/quote">Obten un presupuesto</Link>
                </button>
            </div>
          </div>
          <div className="steps-container3">
            <div className="steps-container4 thq-card">
              <h2 className="thq-heading-2">{props.step1Title}</h2>
              <span className="steps-text14 thq-body-small">
                {props.step1Description}
              </span>
              <label className="steps-text15 thq-heading-3">01</label>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">{props.step2Title}</h2>
              <span className="steps-text17 thq-body-small">
                {props.step2Description}
              </span>
              <label className="steps-text18 thq-heading-3">02</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">{props.step3Title}</h2>
              <span className="steps-text20 thq-body-small">
                {props.step3Description}
              </span>
              <label className="steps-text21 thq-heading-3">03</label>
            </div>
            <div className="steps-container7 thq-card">
              <h2 className="thq-heading-2">{props.step4Title}</h2>
              <span className="steps-text23 thq-body-small">
                {props.step4Description}
              </span>
              <label className="steps-text24 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  step2Description:
    'Nuestros diseñadores expertos trabajarán con usted para crear prototipos personalizados o diseños adaptados a sus especificaciones.',
  step4Description:
    'Una vez completado el proceso de impresión, le entregaremos los productos finales de manera oportuna.',
  step3Title: 'Proceso de impresión',
  step1Title: 'Consulta',
  step4Title: 'Entrega',
  step3Description:
    'Utilizamos tecnología FDM para dar vida a sus diseños con precisión y eficiencia.',
  step2Title: 'Fase de diseño',
  step1Description:
    'Programe una consulta con nuestro equipo para discutir sus necesidades de diseño e impresión 3D.',
}

Steps.propTypes = {
  step2Description: PropTypes.string,
  step4Description: PropTypes.string,
  step3Title: PropTypes.string,
  step1Title: PropTypes.string,
  step4Title: PropTypes.string,
  step3Description: PropTypes.string,
  step2Title: PropTypes.string,
  step1Description: PropTypes.string,
}

export default Steps
